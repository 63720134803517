import { Col, Row } from 'reactstrap';
import React from 'react';
import styled from 'styled-components';
import process from '../../../static/images/about/process.svg';
import process_vertical from '../../../static/images/about/process mobile.svg';

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;

    @media (max-width: 768px) {
        display: none;
    }
`;

const MobileImage = styled.img`
    display: none;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    @media (max-width: 768px) {
        display: block;
    }
`;

export const Timeline = () => {
    return (
        <Row>
            <Col>
                <Image src={process} />
                <MobileImage src={process_vertical} />
            </Col>
        </Row>
    );
};
