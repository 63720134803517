import styled from 'styled-components';

const options = {
    web: ['auto 70%', '85% 50%'],
    cover: ['cover', 'center'],
};

export const Background = styled.div`
    background-size: ${props => (props.size ? props.size : options[props.design] !== undefined ? options[props.design][0] : 'auto 80%')};
    width: 100%;
    height: ${props => (props.height ? props.height : '100%')};
    background-position: ${props =>
        props.position ? props.position : options[props.design] !== undefined ? options[props.design][1] : '50% 100%'};
    z-index: ${props => (props.index !== undefined ? props.index : -1)};
    background-color: ${props => props.color};
    background-image: url("${props => props.bg}");
    background-repeat: no-repeat;
    position: ${props => (props.pos ? props.pos : 'absolute')};

    box-shadow: ${props => (props.shadow ? 'inset 0 0 0 84vh rgba(0,0,0,0.3)' : null)};
    filter: ${props => (props.blur ? `blur(${props.blur}px)` : 'none')};

    @media (max-width: 768px) {
      background-position: ${props =>
          props.mobilePos ? props.mobilePos : props.design === 'cover' ? 'center' : '40vw 100%'}; // TODO: FIX THIS
      background-image: url("${props => (props.vertical ? props.vertical : props.bg)}");
      height: ${props => props.mobileHeight};
    }
`;
