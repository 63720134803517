import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';
import { PRIMARY_TEXT_COLOR, SECONDARY_BG_COLOR, TEXT_FONT } from '../../constants';
import { CallToActionButton, MainText } from '../shared';
import { Col, Container, Row } from 'reactstrap';

const Interested = styled.div`
    font-size: 18px;
    font-family: ${TEXT_FONT};
    color: ${params => (params.color ? params.color : PRIMARY_TEXT_COLOR)};
    margin-bottom: 30px;
    margin-top: 30px;

    @media (max-width: 992px) {
        font-size: 20px;
    }

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const LetsGetStartedContainer = styled.div`
    background-color: ${props => (props.bg ? props.bg : SECONDARY_BG_COLOR)};
    height: 70vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-height: 700px) {
        height: 85vh;
    }
`;

export const LetsGetStarted = ({ bg, color, buttonPrimary = false }) => {
    return (
        <>
            <LetsGetStartedContainer bg={bg}>
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <MainText color={color ? color : PRIMARY_TEXT_COLOR} size="32px" marginBottom="20px">
                                Let’s get started.
                            </MainText>
                            <Interested color={color}>
                                Interested? Let's talk about your project today. Send us an email and we will get back to you as soon as we
                                can.
                            </Interested>
                            <Link to={'/contact'}>
                                <CallToActionButton shadow={null} mb={'0px'} primary={buttonPrimary}>
                                    Let’s start
                                </CallToActionButton>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </LetsGetStartedContainer>
        </>
    );
};
